import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import smoothscroll from 'smoothscroll-polyfill';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// kick off the polyfill!
console.log('kick off polyfill!')
smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;
